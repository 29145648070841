@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&family=Quicksand:wght@300;400;600&family=Staatliches&display=swap');

html,
body {
	margin: 0;
	background: #26243c;
	overflow-x: hidden;
	scroll-behavior: smooth;
}
.letsTalkHolder {
	display: flex;
	justify-content: center;
	padding-bottom: 30px;
}
.centerBtn {
	font-family: 'Quicksand', sans-serif;
	text-transform: none !important;
	padding: 15px !important;
	padding-left: 25px !important;
	padding-right: 25px !important;
	font-size: 16px !important;
}
section {
	margin-left: 20px;
	margin-right: 20px;
	margin-bottom: 50px;
}

.app {
	max-width: 1200px;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
}
.serviceBg {
	/* max-width: 1200px; */
	height: 100vh;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	background-image: url('../images/particles-lg.png');
	background-position: right;
	background-repeat: no-repeat;
	background-size: contain;
}
.wait {
	width: '100%';
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ddd;
	font-size: 20px;
	font-family: 'Quicksand', sans-serif;
}
.hero {
	margin-top: 150px;
	color: #fff;
	height: 320px;
}
h4 {
	margin: 0;
	font-family: 'Quicksand', sans-serif;
	margin-bottom: 20px;
	font-weight: 300;
}
h1 {
	margin: 0;
	font-family: 'Staatliches', cursive;
	font-size: 40px;
	max-width: 60%;
	letter-spacing: 1px;
	font-weight: 100;
}
h3 {
	margin: 0;
	font-family: 'Staatliches', cursive;
	font-size: 25px;
	letter-spacing: 1px;
	font-weight: 100;
	color: #fff;
	margin-bottom: 50px;
}
h2 {
	margin: 0;
	font-family: 'Staatliches', cursive;
	font-size: 25px;
	max-width: 60%;
	letter-spacing: 1px;
	font-weight: 100;
	color: #fbd000;
	margin-bottom: 30px;
}
.darkBtn {
	display: inline-block;
	background-color: #141221;
	color: #534b4b;
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 5px;
	font-family: 'Nunito', sans-serif;
	margin-top: 30px;
	cursor: pointer;
	transition-timing-function: ease;
	transition-duration: 0.6s;
	z-index: 2;
}
.darkBtn:hover {
	color: #fff;
}

.jira {
	position: absolute;
	right: -400px;
	opacity: 0.1;
	top: 100px;
}
.Ui3d {
	position: absolute;
	left: -500px;
	z-index: 0;
}

.proccess_card_holder {
	display: flex;
	margin-top: 30px;
	flex-direction: row;
	align-content: space-between;
}
.proccess_card {
	flex: 1;
	position: relative;
	z-index: 1;
}
.proccessImg {
	width: 100%;
	border-radius: 10px;
}
.spacer {
	width: 60px;
}
.spacer-s {
	width: 30px;
}
.proccessTxt {
	color: #fff;
	padding: 10px;
	font-family: 'Quicksand', sans-serif;
	font-weight: 100;
}
.mark {
	display: none;
}
.step {
	position: absolute;
	display: flex;
	width: 30px;
	height: 30px;
	background-color: #fbc900;
	justify-content: center;
	align-items: center;
	border-radius: 100px;
	left: -15px;
	top: -15px;
	font-family: 'Staatliches', cursive;
}
.serviceCardsHolder {
	display: flex;
	margin-bottom: 30px;
}
.serviceCard {
	position: relative;
	flex: 1;
	background-color: #191729;
	padding: 30px;
	border-radius: 10px;
	color: #ddd;
	align-items: center;
	min-height: 180px;
	cursor: pointer;
	transition-timing-function: ease;
	transition-duration: 0.6s;
}
.serviceCard:hover {
	background-color: #1c1a26;
	color: #fff;
}
.serviceCard p {
	font-family: 'Quicksand', sans-serif;
	font-size: 14.5px;
	text-align: center;
	font-weight: 100;
}
.serviceCard h4 {
	text-align: center;
	font-size: 21px;
}
.iconHolder {
	display: flex;
	background-color: #222034;
	position: absolute;
	width: 45px;
	height: 30px;
	justify-content: center;
	align-items: center;
	border-radius: 100px;
	bottom: 20px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}
.hide {
	visibility: hidden;
}
.stacksHolder {
	background-color: #1f1d2b;
	padding: 50px;
	background-image: linear-gradient(45deg, #1f1d2b, #1c1a26);
	transform: skewY(-3deg);
	margin-top: 100px;
	bottom: 0;
	left: 0;
	right: 0;
}
.stacks {
	transform: skewY(3deg);
	font-size: 18px;
	color: #ddd;
	text-align: center;
	font-family: 'Quicksand', sans-serif;
	font-weight: 100;
}
.mergeStacks {
	display: none;
}
.stacksLogo {
	height: 30px;
	margin-left: 20px;
	margin-right: 20px;
}

.worksHolder {
	background-color: #181727;
	padding: 50px;
	background-image: linear-gradient(45deg, #181727, #181727);
	transform: skewY(-3deg);
	bottom: 0;
	left: 0;
	right: 0;
}
.works {
	transform: skewY(3deg);
}
.works h3 {
	text-align: center;
}
.worksCardHolder {
	display: flex;
	flex-direction: row;
}
.workCard {
	flex: 1;
}
.workImage {
	width: 100%;
	border-radius: 10px;
}
.workCardFooter {
	display: flex;
	color: #fff;
	margin-top: 20px;
}
.appLogo {
	width: 50px;
	border-radius: 15px;
	margin-right: 10px;
}
.appTxt {
	font-family: 'Staatliches', cursive;
	font-size: 18px;
	letter-spacing: 3px;
}
.appDescTxt {
	font-family: 'Quicksand', sans-serif;
}
.footer {
	display: flex;
	padding-top: 100px;
	padding-bottom: 50px;
	color: #ddd;
	text-align: right;
	font-family: 'Quicksand', sans-serif;
	align-items: center;
}
.footerLogo {
	width: 40px;
	margin-right: 20px;
	margin-left: 50px;
}
.details {
	position: relative;
	width: 100%;
}
.show {
	position: absolute;
	background-color: #fbc900;
	color: #222034;
	padding: 5px;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 50px;
	right: 10px;
	top: 10px;
	font-family: 'Nunito', sans-serif;
	text-transform: uppercase;
	font-size: 12px;
	letter-spacing: 1px;
	cursor: pointer;
	transition: all .2s ease-in-out;
}
.show:hover {
	transform: scale(1.1);
}
.menuIconHolder {
	display: none;
}

.serviceDetailsContainer {
	max-width: 1200px;
	position: relative;
	top: 0;
	background: #191629;
	border-radius: 10px;
	margin: 20px;
	align-self: center;
}
.detailsContent {
	padding: 50px;
	padding-top: 100px;
}
.detailsContent h1 {
	color: #fbc900;
}
.detailsContent p {
	color: #f2f2f2;
	font-family: 'Quicksand', sans-serif;
	font-size: 20px;
}
.detailsImage {
	width: 100%;
	height: auto;
}
.closeDetails {
	position: absolute;
	top: 20px;
	right: 20px;
	background-color: #fff;
	display: flex;
	width: 40px;
	height: 40px;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	cursor: pointer;
}

.contact_me {
	background-color: #191629;
	width: 100%;
	left: 0;
	right: 0;
	top: 0;
	position: absolute;
	padding-top: 150px;
	padding-bottom: 50px;
	padding-left: 40px;
	padding-right: 40px;
	z-index: -100;
}
.contactInput {
	display: block;
	padding: 15px;
	width: 400px;
	border-radius: 5px;
	border: 0px;
	margin-bottom: 10px;
}
.contactTextArea {
	display: block;
	width: 400px;
	height: 100px;
	border: 0px;
	border-radius: 5px;
	padding: 15px;
}
.contactBtn {
	border: 0px;
}
.contactWrapper {
	max-width: 1200px;
	margin: 0 auto;
}
/**
* Mobile Styling
*/
@media (max-width: 880px) {
	.logo {
		width: 50px !important;
		height: auto;
	}
	.mark {
		display: block;
		position: absolute;
		width: 5px;
		height: 20px;
		margin-top: 10px;
		background-color: #fbc900;
		left: 0;
	}
	.menuIconHolder {
		display: block;
		flex: 1;
		text-align: right;
	}
	.menuHolder {
		display: none;
	}
	.contactBtnHolder {
		display: none;
	}
	.jira {
		width: 90%;
		height: auto;
		margin-right: 300px;
	}
	.hero {
		height: 200px;
	}
	.hero h1 {
		max-width: 100% !important;
		font-size: 30px;
	}
	.proccess_card_holder {
		flex-direction: column;
	}
	.proccess_card {
		max-width: 100%;
		margin: 0 auto;
		margin-bottom: 50px;
	}
	.proccessImg {
		max-height: 300px;
	}
	.step {
		border-radius: 0;
		left: 0;
		top: 0;
		border-top-left-radius: 10px;
	}
	.serviceCardsHolder {
		flex-direction: column;
		margin: 0;
	}
	.serviceCard {
		margin-bottom: 10px;
	}
	.show {
		display: none;
	}
	.appDescTxt {
		font-size: 11px;
	}
	.appLogo {
		width: 40px;
		height: 40px;
	}
	.spacer-s {
		width: 10px;
	}
	.stacksLogo {
		height: 25px;
	}
	.stacksHolder {
		margin-top: 0px;
	}
	.footer {
		flex-direction: column;
		padding-top: 50px;
		font-size: 12px !important;
	}

	.footer img {
		margin: 0;
		margin-bottom: 20px;
	}

	.mergeStacks {
		display: none;
		transform: skewY(3deg);
		text-align: center;
		color: #ddd;
		font-size: 18px;
	}
	.mergeStacks img {
		width: 100%;
		height: auto;
	}
	.menuBtn {
		cursor: pointer;
	}
	.mobileMenuHolder {
		display: flex !important;
		flex-direction: column;
		position: absolute;
		width: 100%;
		padding-top: 50px;
		padding-bottom: 50px;
		background-color: #191629;
		left: 0;
		right: 0;
		top: 0;
		clip-path: polygon(0 0, 100% 0, 100% 93%, 0 100%);
		justify-content: center;
		align-items: center;
		border-bottom: 10px solid #fbc900;
	}
	.menuItem {
		margin-bottom: 30px;
		font-size: 20px;
		text-transform: uppercase;
		cursor: pointer;
		letter-spacing: 2px;
	}
	.closeBtn {
		position: absolute;
		left: 20px;
		top: 20px;
		cursor: pointer;
	}
	.center {
		margin: 0 auto;
		padding: 15px !important;
		padding-left: 20px !important;
		padding-right: 20px !important;
	}

	.serviceDetailsContainer {
		top: 0;
		background: #191629;
		z-index: 9999;
	}
	.detailsContent {
		margin: 15px;
		padding: 25px;
	}
	.closeDetails {
		width: 30px;
		height: 30px;
		top: 10px;
		right: 10px;
	}
	.detailsContent h1 {
		font-size: 25px;
	}
	.detailsContent p {
		font-size: 15px;
	}
	.contact_me {
		height: 100vh;
	}
	.contactInput {
		width: calc(100% - 100px);
	}
	.contactTextArea {
		width: calc(100% - 100px);
	}
}
