.header {
	position: fixed;
	display: flex;
	top: 0px;
	right: 0;
	left: 0;
	background-color: #26243c;
	align-items: center;
	height: 100px;
	justify-content: center;
	z-index: 90;
	transition: all .2s ease-in-out;
}
.mobileMenuHolder {
	display: none;
}
.shadow {
	box-shadow: 20px 20px 60px #181727, -20px -20px 60px #26243c;
}
.headerContent {
	position: fixed;
	display: flex;
	align-items: center;
	align-self: center;
	font-family: 'Nunito', sans-serif;
	color: #ffffff;
	padding: 30px;
	right: 0;
	left: 0;
	z-index: 9;
	max-width: 1175px;
	margin: 0 auto;
}
.logoHolder {
	width: 66px;
}
.logo {
	width: 66px;
	height: auto;
}
.menuHolder {
	flex: 1;
	align-items: center;
}
.menuHolder ul {
	display: flex;
	list-style: none;
	flex-direction: row;
	justify-content: center;
}
.menuHolder ul li {
	width: 100px;
	position: relative;
	margin-left: 40px;
	margin-right: 40px;
	text-transform: uppercase;
	font-size: 15px;
	cursor: pointer;
	z-index: 1;
}

.contactBtn {
	align-self: flex-end;
	display: inline-block;
	text-transform: uppercase;
	font-size: 12px;
	color: #26243c;
	background-color: #fbc900;
	padding: 8px;
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 5px;
	font-weight: bold;
	cursor: pointer;
	transition: all .2s ease-in-out;
}
.contactBtn:hover {
	transform: scale(1.1);
}
.active {
	color: #fbc900;
}
